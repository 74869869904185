import React, { useState } from 'react';

import _1 from '../media/BoardCellList/1.svg';
import _2 from '../media/BoardCellList/2.svg';
import _3 from '../media/BoardCellList/3.svg';
import _4 from '../media/BoardCellList/4.svg';
import _5 from '../media/BoardCellList/5.svg';
import _6 from '../media/BoardCellList/6.svg';
import _7 from '../media/BoardCellList/7.svg';
import _8 from '../media/BoardCellList/8.svg';
import _9 from '../media/BoardCellList/9.svg';
import _10 from '../media/BoardCellList/10.svg';
import _11 from '../media/BoardCellList/11.svg';
import _12 from '../media/BoardCellList/12.svg';
import _13 from '../media/BoardCellList/13.svg';
import _14 from '../media/BoardCellList/14.svg';
import _15 from '../media/BoardCellList/15.svg';
import _16 from '../media/BoardCellList/16.svg';
import _17 from '../media/BoardCellList/17.svg';
import _18 from '../media/BoardCellList/18.svg';
import _19 from '../media/BoardCellList/19.svg';
import _20 from '../media/BoardCellList/20.svg';
import _21 from '../media/BoardCellList/21.svg';
import _22 from '../media/BoardCellList/22.svg';
import _23 from '../media/BoardCellList/23.svg';
import _24 from '../media/BoardCellList/24.svg';
import _25 from '../media/BoardCellList/25.svg';

export const cellsvg = {
    1: _1,
    2: _2,
    3: _3,
    4: _4,
    5: _5,
    6: _6,
    7: _7,
    8: _8,
    9: _9,
    10: _10,
    11: _11,
    12: _12,
    13: _13,
    14: _14,
    15: _15,
    16: _16,
    17: _17,
    18: _18,
    19: _19,
    20: _20,
    21: _21,
    22: _22,
    23: _23,
    24: _24,
    25: _25,
};

const Board = () => {
    const s = useState(Array(25).fill(false));
    
    return (
        <div>
            {Array.from({ length: 25 }, (_, index) => (
                <img 
                    key={index + 1} 
                    src={cellsvg[index + 1]} 
                    alt={`Cell ${index + 1}`} 
                    width={100} 
                    height={100} 
                />
            ))}
        </div>
    )
}

export default Board;
