import React from 'react';

import './Progressbar.css';

const ProgressBar = ({ current, max }) => {
    const percentage = (current / max) * 100;

    return (
        <div className="progress-bar-container">
            <div 
                className="progress-bar-fill" 
                style={{ width: `${percentage}%` }}
            ></div>
        </div>
    );
};

export default ProgressBar;