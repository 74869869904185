import * as d3 from 'd3';
import dayjs from 'dayjs';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useInitData } from '@vkruglikov/react-telegram-web-app';

import ProgressBar from './components/Progressbar';

import casino_logo from './media/casino.png';
import header_logo from './media/1win-normal.svg';
import header_win from './media/wins.webp';
import game_footer from './media/minesfooter.webp';
import game_contentbg from './media/boardborder.png'
import game_crack1 from './media/crack1.svg';
import game_crack2 from './media/crack2.svg';
import game_star from './media/star.svg';

import { cellsvg } from './components/Board';

import './App.css';

const App = () => {
    const [cells, setCells] = useState(Array(25).fill(false));
    const [progress, setProgress] = useState(0);
    const [signaldelay, setSignaldelay] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [chance, setChange] = useState('0');
    const [availableSignals, setAvailableSignals] = useState(0);
    const [cooldown, setCooldown] = useState(0);
    const [user, setUser] = useState(null); 

    const [initDataUnsafe] = useInitData();
    const [loading, setLoading] = useState(true);

    const getSignal = (newCells) => {
        setCells(newCells);
    };

    useEffect(() => {
        setLoading(true);

        const get_user = async () => {
            return axios.get('https://mines.tg.normalizex.dev/api/user?q=' + initDataUnsafe.user.id).then((response) => {
                setAvailableSignals(response.data.available);
                setSignaldelay(response.data.delay);
                setCooldown(response.data.cooldown);
                if (response.data.available > 0){
                    setDisabled(false);
                }
                setUser(response.data);
            }).catch(() => {
                setAvailableSignals(0);
            });
        }

        get_user().catch(_ => {
            console.log('errr with fetch');
        }).finally(() => {
            setLoading(false);
        });

        const userupdateInterval = setInterval(() => {
            get_user().catch(_ => {
                console.log('errr with fetch');
            })
        }, 60 * 1000);

        return () => {
            clearInterval(userupdateInterval);
        }
    }, [initDataUnsafe]);

    useEffect(() => {
        if (signaldelay > 0){
            const signal_timeout = setTimeout(() => {
                setSignaldelay((current) => {
                    if (current <= 1){
                        clearTimeout(signal_timeout);
                        return 0;
                    }else{
                        return current - 1;
                    }
                });
            }, 1000);

            return () => {
                clearTimeout(signal_timeout);
            }
        }
    }, [signaldelay]);

    const updateProgress = async () => {
        let process = localStorage.getItem('process-active');
        if (process === 'false') return;

        setProgress((prevProgress) => {
            if (prevProgress >= 130) {
                localStorage.setItem('process-active', 'false');
                setProgress(0);
                setTimeout(() => {
                    axios.post('https://mines.tg.normalizex.dev/api/user/signal?q=' + initDataUnsafe.user.id).then((response) => {
                        if (response.data.available > 0){
                            getSignal(response.data.mines);
                            setChange(response.data.chance)
                        }
                        setAvailableSignals(response.data.available);
                        setSignaldelay(response.data.delay);
                        setCooldown(response.data.cooldown);
                    }).catch(err => {
                        if (err.response){
                            if (err.response.data.chance !== 0){
                                getSignal(err.response.data.mines);
                                setChange(err.response.data.chance)
                            };

                            setAvailableSignals(err.response.data.available);
                            setSignaldelay(err.response.data.delay);
                            setCooldown(err.response.data.cooldown);
                        };
                    })
                }, 250);

                return prevProgress;
            }
            else if (prevProgress >= 0 && prevProgress <= 10){
                return prevProgress + 32;
            }
            else if (prevProgress > 10 && prevProgress <= 40){
                return prevProgress + 20;
            }
            else if (prevProgress > 40 && prevProgress <= 70) {
                return prevProgress + 16.1;
            }
            else if (prevProgress > 70) {
                return prevProgress + 12;
            }
        })
    }

    useEffect(() => {
        localStorage.setItem('process-active', 'false');
        const progressBarInterval = setInterval(() => {
            updateProgress();
        }, 100);

        return () => {
            clearInterval(progressBarInterval);
        }
    }, []);

    if (loading){
        return (
            <div className='App'>

            </div>
        )
    }

    if (cooldown > 0){
        return (
            <div>
                <div className='center-app'>
                    <div className='center-box '>
                        <div className='item'>
                            <div className='item'>
                                <h3>Oops, signals have run out ⚠️</h3>
                                <h3>Please wait a bit ⏳</h3>
                            </div>
                            <div className='item' style={{ marginTop: '20%' }}>
                            <h3>Remaining: {Math.floor(cooldown / 3600).toString().length === 1 ? '0' + Math.floor(cooldown / 3600) : Math.floor(cooldown / 3600)}:{Math.floor((cooldown % 3600) / 60).toString().length === 1 ? '0' + Math.floor((cooldown % 3600) / 60) : Math.floor((cooldown % 3600) / 60)}</h3>
                                <ProgressBar current={10800 - cooldown} max={10800} />

                                <h3>🚀 While you're waiting, why not check other bot? <a className='btn' href={`https://t.me/Aviator_casino_bot${user.code ? "?start=" + user.code : ''}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', padding: '0' }}> Aviator ✈️ </a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='App'>
            <div class="item header">
                <img alt='Right' className='left-image' src={casino_logo} />
                <img alt='Right' className='right-image' src={header_win} />
            </div>
            <div className="item">
                <div className='game'>
                    <div className='game-content-wrapper' >
                        <div className='game-left'>
                            <img alt='Left' className='footer-left-image' src={game_crack2} />
                        </div>
                        <div className='game-right'>
                            <img alt='Right' className='footer-right-image' src={game_crack1} />
                        </div>
                        <div className='game-content' style={{ backgroundImage: `url(${game_contentbg})` }}>
                            <div className='game-grid' >
                                {cells.map((active, index) => (
                                    <div
                                        key={index}
                                        className="game-cell"
                                    >
                                        <img 
                                            key={index + 1} 
                                            src={active ? game_star : cellsvg[index + 1]} 
                                            alt={`Cell ${index + 1}`} 
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='game-footer'>
                        <img alt='Footer' className='footer-image' src={game_footer} />
                    </div>
                </div>
            </div>
            <div className="item">
                <ProgressBar current={progress} max={100} />
            </div>
            <div className="item text-left">
                <div className='stats-container'>
                    <p className="bold-text">Mines: 3 <i className='bx bxs-bomb' style={{ color: 'black' }}></i></p>
                    <p className="bold-text">Chance: {chance}%</p>
                    { cooldown ? <p className='neon-white-text'>Cooldown: {Math.floor(cooldown / 3600)} hours {Math.floor((cooldown % 3600) / 60)} minutes</p> : <p className='neon-white-text'>Before The Signal: {signaldelay}</p> }
                </div>
            </div>
            <div className="item">
                <button 
                    disabled={disabled} 
                    className={`btn ${disabled || availableSignals == 0 || signaldelay > 0 || cooldown > 0 ? 'btn-disabled' : ''}`} 
                    style={{ marginBottom: '30px' }} 
                    onClick={() => {
                        if (disabled || signaldelay > 0 || cooldown ) return;
                        if (localStorage.getItem('process-active') === 'true') return;

                        if (signaldelay === 0) {
                            axios.get('https://mines.tg.normalizex.dev/api/user?q=' + initDataUnsafe.user.id).then((response) => {
                                setAvailableSignals(response.data.available);
                                setSignaldelay(response.data.delay);
                                if (response.data.available > 0){
                                    setDisabled(false);
                                    localStorage.setItem('process-active', 'true');
                                }
                            }).catch(() => {
                                setAvailableSignals(0);
                            });
                        };
                }}>
                    GET SIGNAL
                </button>
            </div>
        </div>
    )
}

export default App;